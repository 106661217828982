import ProductIngredients from './ProductIngredients.vue';
import ProductIngredientsCreate from './Create.vue';
import ProductIngredientsEdit from './Edit.vue';
import ProductIngredientsShow from './Show.vue';

export default [
  {
    path: '/product_ingredients',
    name: 'ProductIngredients',
    component: ProductIngredients,
  },
  {
    path: '/product_ingredients/create',
    name: 'ProductIngredientsCreate',
    component: ProductIngredientsCreate,
  },
  {
    path: '/product_ingredients/edit/:id',
    name: 'ProductIngredientsEdit',
    component: ProductIngredientsEdit,
  },
  {
    path: '/product_ingredients/:id',
    name: 'ProductIngredientsShow',
    component: ProductIngredientsShow,
  },
];
