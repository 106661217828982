<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <a
                    class="btn btn-primary mr-1"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="true"
                    aria-controls="collapseExample"
                    >Filter</a
                  >
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'ProductIngredientsCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="filter.search"
                    label="Search"
                    @keyup.enter="getData"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary" @click="getData">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="collapse" id="collapseExample">
                <div class="card card-body">
                  <p class="font-weight-bold">Filter</p>
                  <div class="row">
                    <div class="col-12">
                      <button
                        class="btn btn-primary w-100 mt-1"
                        @click="getData"
                      >
                        Filter Product Ingredients
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                v-if="!loading"
                :items="product.data"
                item-key="id"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductIngredientsShow',
                          params: { id: row.item.id },
                        }"
                        >{{ row.item.name }}</router-link
                      >
                    </td>
                    <td>{{ row.item.price }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductIngredientsEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        @click="deleteProduct(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <div class="d-inline-block overflow-auto">
                <v-pagination
                  v-model="page"
                  :length="product.meta?.last_page"
                  :total-visible="product.meta?.per_page"
                  @input="getData"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import { apiGet } from '../../services/api';
export default {
  name: 'ProductIngredients',
  data() {
    return {
      page: 1,
      pageVisible: 8,
      name: 'ProductIngredients',
      product: [],
      filter: {
        search: '',
        category_id: '',
        product_code: '',
        type_id: '',
      },
      categories: [],
      types: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.getData();
    },
    resetFilter() {
      this.search = '';
      Object.entries(this.filter).forEach((e) => {
        this.filter[e[0]] = '';
      });
      this.getData().then(() => {
        this.page = 1;
      });
    },
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + 'product_ingredient', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            page: this.page,
            search: this.filter.search,
          },
        })
        .then((result) => {
          this.loading = false;
          this.product = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteProduct(id) {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this imaginary file!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + 'product_ingredient/' + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(() => this.getData())
            .catch((err) => {
              swal('Error!', err.message, 'error');
            });
          swal('Poof! Your product file has been deleted!', {
            icon: 'success',
          });
        } else {
          swal('Your product file is safe!');
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ];
    },
  },
};
</script>
