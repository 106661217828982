<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product Ingredients</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} edit</h4>
            </div>
            <form @submit.prevent="productUpdate" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Price</label>
                  <money v-model="form.price" class="form-control"></money>
                  <small class="text-danger" v-if="errors.price">{{
                    errors.price[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Unit</label>
                  <v2-select
                    v-model="form.unit_id"
                    option-value="id"
                    option-text="name"
                    placeholder="Select Unit"
                  >
                  </v2-select>
                  <small class="text-danger" v-if="errors.unit">{{
                    errors.unit[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <router-link
                  class="btn btn-secondary"
                  :to="{ name: 'ProductIngredients' }"
                  >Cancel</router-link
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import { apiGet } from '../../services/api';

export default {
  name: 'ProductIngredientsEditV2',
  data() {
    return {
      name: 'Form Edit Product Ingredients',
      form: {
        name: '',
        price: '',
        unit_id: '',
      },
      units: [],
      isSubmitting: false,
      errors: {},
    };
  },

  methods: {
    getUnits() {
      apiGet('unit')
        .then((result) => {
          this.units = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detail() {
      let id = this.$route.params.id;
      apiGet('product_ingredient/' + id)
        .then((result) => {
          this.form.name = result.data.data.name;
          this.form.price = result.data.data.price;
          this.form.unit_id = result.data.data.product_unit.unit_id;
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    productUpdate() {
      this.isSubmitting = true;

      let id = this.$route.params.id;
      let formData = new FormData();
      formData.set('name', this.form.name);
      formData.set('unit_id', this.form.unit_id);
      formData.set('price', this.form.price);
      axios
        .post(
          this.$store.state.api + 'product_ingredient/update/' + id,
          formData,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$noty.success('Your product ingredient has been updated!');
          this.$router.push({ name: 'ProductIngredients' });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
  mounted() {
    this.getUnits();
    this.detail();
  },
};
</script>
