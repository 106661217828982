<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }} {{ form.name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Product</div>
        <div class="breadcrumb-item">{{ name }} {{ form.name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div class="row" v-if="!loading">
        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} {{ form.name }}</h4>
            </div>
            <form @submit.prevent="productUpdate" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="form.name"
                  />
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Category</label>
                    <select
                      disabled
                      class="custom-select"
                      v-model="form.category_id"
                    >
                      <option selected="" value=""
                        >Open this select menu</option
                      >
                      <option
                        v-for="c in categories"
                        :key="c.id"
                        :value="c.id"
                        >{{ c.name }}</option
                      >
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Type</label>
                    <select
                      disabled
                      class="custom-select"
                      v-model="form.type_id"
                    >
                      <option selected="" value=""
                        >Open this select menu</option
                      >
                      <option v-for="t in types" :key="t.id" :value="t.id">{{
                        t.name
                      }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label>Unit</label>
                  <div class="row">
                    <div
                      class="col"
                      v-for="(unit, index) in form.unit_name"
                      :key="index"
                    >
                      <ul class="list-group">
                        <li class="list-group-item">
                          {{ unit }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <img :src="form.image" alt="" style="height:100px" />
                </div>
              </div>
            </form>
          </div>
          <product-component></product-component>
          <product-additional></product-additional>
        </div>
        <div class="col-6">
          <product-price></product-price>
          <product-stock :stocks="form.stock"></product-stock>
          <product-ladies :ladies="form.ladies_list"></product-ladies>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { apiGet } from "../../services/api";


export default {
  name: "ProductIngredientsShow",
  data() {
    return {
      name: "Detail Product Ingredient",
      form: {
        name: "",
        price: "",
        unit_id: "",
      },
      categories: [],
      units: [],
      types: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
    this.getCategories();
    this.getTypes();
    this.getUnits();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("product_ingredient/" + id)
        .then((result) => {
          this.form = result.data.data;
          this.product_price = result.data.data.price;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUnits() {
      apiGet("unit")
        .then((result) => {
          this.units = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
